import { React } from "react";
import TextField from "@mui/material/TextField";

function Search({ onSearchChange }) {
  const handleSearchChange = (event) => {
    onSearchChange(event.target.value);
  };

  return (
    <div className="search">
      <TextField
        id="outlined-basic"
        variant="outlined"
        fullWidth
        label="Search"
        onChange={handleSearchChange} // Add this line
      />
    </div>
  );
}

export default Search;
