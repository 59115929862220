import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import Search from './Search'
import Favorites from './Favorites'
import Collapsible from './Collapsible'


function Menu({setLoading, neuroglancerLink, setNeuroglancerLink, imageList, faveList, setFaveList, removeFavorite, setCurrentImageDetails, addImageToFavorites}) {

  const menuRef = useRef(null);
  const [link, setLink] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);


  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (link) {
      setLoading(true);
      handleLinkClick();
      setMenuOpen(!menuOpen);
    }
  }, [link]);

  const handleLinkClick = async () => {
    try {
      axios.get(`${link}`).then(response => {
        setNeuroglancerLink(response.data.neuroglancer);
        setLoading(false)
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  function closeMenu() {
    setMenuOpen(false);
  }

  return (
    <div className='menuContainer'>
      <div className='menuButtons'>
        <button className="toggle-button" onClick={() => setMenuOpen(true)}>
          Menu
        </button>
        { neuroglancerLink && (
          <button className='addFaveButton' onClick={addImageToFavorites}> 
            <FontAwesomeIcon icon={icon({name: 'thumbtack'})} /> &nbsp;
            Pin Current Image to Favorites
          </button>
        )}
      </div>


      <div ref={menuRef} className={`menu ${menuOpen ? 'container-open' : 'container-closed'}`}>
        {menuOpen && (
          <button className="menu-close-button" onClick={() => setMenuOpen(false)}>
            <FontAwesomeIcon icon={icon({name: 'circle-xmark'})} />
          </button>
        )}

        
        {menuOpen && (
          <div className='menu-items'>
            {menuOpen && faveList.length > 0 && (<Favorites 
              faveList={faveList} 
              removeFavorite={removeFavorite} 
              menuOpen={menuOpen} 
              closeMenu={closeMenu} 
              setLink={setLink}
            />
            )}

            {menuOpen && imageList.map((c, index) => {
              return (
                <Collapsible
                  key={index}
                  header={c.type}
                  details={c.details}
                  setLink={setLink}
                  menuOpen={menuOpen}
                  closeMenu={closeMenu}
                  setCurrentImageDetails={setCurrentImageDetails}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default Menu;
