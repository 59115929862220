import React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

function CustomPagination({ totalPages, currentPage, handlePageChange }) {
  const handleChange = (event, page) => {
    handlePageChange(page);
  };

  return (
    <Stack spacing={2}>
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handleChange}
        showFirstButton
        showLastButton
      />
    </Stack>
  );
}

export default CustomPagination;
