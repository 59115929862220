import React from 'react'


function ImageViewer(props) {
  return (
    <div className="middle">
      {props.neuroglancerLink && <iframe title="Neuroglancer Viewer" src={props.neuroglancerLink}></iframe>}
    </div>
  )
}

export default ImageViewer