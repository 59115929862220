import React, { useState } from 'react';
import CustomPagination from './Pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

function Collapsible({ header, details, setLink, menuOpen, closeMenu, setCurrentImageDetails }) {
  const [itemsOpen, setItemsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  function openItems() {
    setItemsOpen(true);
  }

  function closeItems() {
    setItemsOpen(false);
  }

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedItems = details.slice(startIndex, endIndex);
  const totalPages = Math.ceil(details.length / itemsPerPage);  

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="collapsible">
      {menuOpen && (
        <div className="collapsible-header" onClick={() => itemsOpen ? closeItems() : openItems()}>
          {header} <FontAwesomeIcon icon={icon({name: 'caret-down'})}/>
        </div>
      )}
      
      {menuOpen && itemsOpen && (
        <div className={`collapsible-content ${itemsOpen ? 'expanded-content' : ''}`}>
          {displayedItems.map((d) => {
            return (
              <div className="collection" key={d.id}>
                <div className="c_name">
                <a href='#' onClick={(event) => {
                    event.preventDefault();
                    setLink(d.links);
                    closeMenu();
                    setCurrentImageDetails(d);
                  }} className="image-link-style" title='Click to view image'>{d.name}</a>
                  &nbsp;
                  <FontAwesomeIcon icon={icon({name: 'caret-right'})} />
                </div>        
              </div>
            );
          })}
        </div>
      )}
      {menuOpen && itemsOpen && totalPages > 1 && (
        <div className="pagination">
          <CustomPagination
            totalPages={totalPages}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
          />
        </div>
      )}
    </div>
  );
}

export default Collapsible;
