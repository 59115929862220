import './App.css';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import Menu from './components/Menu';
import Header from './components/Header';
import ImageViewer from './components/ImageViewer';
import Footer from './components/Footer';
// import Search from './components/Search';


function App() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isLoading, setLoading] = useState(true);
  const [neuroglancerLink, setNeuroglancerLink] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [faveList, setFaveList] = useState([]);
  const [currentImageDetails, setCurrentImageDetails] = useState([]);

  function addImageToFavorites() {
    if (neuroglancerLink && !faveList.includes(currentImageDetails)) {
      const updatedFaveList = [...faveList, currentImageDetails];
      setFaveList(updatedFaveList);
      localStorage.setItem('faveList', JSON.stringify(updatedFaveList));
    }
  }

  function removeFavorite(itemToRemove) {
    const updatedFaveList = faveList.filter(item => item !== itemToRemove);
    setFaveList(updatedFaveList);
    localStorage.setItem('faveList', JSON.stringify(updatedFaveList));
  }
  
  useEffect(() => {
    const storedFaveList = localStorage.getItem('faveList');
    if (storedFaveList) {
        setFaveList(JSON.parse(storedFaveList));
    }
  }, []);

  // Use a separate state variable to cache imageList
  const [imageListCache, setImageListCache] = useState({});
  // const [inputText, setInputText] = useState("");

  useEffect(() => {
    // Check if the imageList data is already cached
    if (imageListCache["data"]) {
      setImageList(imageListCache["data"]);
      setLoading(false);
    } else {
      const headers = {
        'Accept': '*/*'
      };
      axios.get(apiUrl, { headers })
        .then(response => {
          const data = response.data.collections;
          setImageList(data);

          // Cache the imageList data
          setImageListCache({ "data": data });

          setLoading(false);
        });
    }
  }, [imageListCache]);

  if (isLoading) {
    return (
      <div className="App">
        <Header />
        <div className='menuContainer'>
          <div className='menuButtons'>
            <button className="toggle-button"></button>
          </div>
          <div className='menu container-closed'></div>
        </div>
        {neuroglancerLink ? <ImageViewer neuroglancerLink={neuroglancerLink} /> : <div className='middle noLink loading-text'>L O A D I N G &nbsp;<FontAwesomeIcon icon={icon({name: 'rotate'})} /> </div>}
        <Footer />
      </div>
    );
  }

  // const filteredImageList = imageList.filter(image => image.details[0].name.toLowerCase().includes(inputText.toLowerCase()));

  return (
    <div className="App">
      <Header/>

      {imageList && <Menu
        isLoading={isLoading} 
        setLoading={setLoading}
        setNeuroglancerLink={setNeuroglancerLink}
        neuroglancerLink={neuroglancerLink}
        imageList={imageList}
        faveList={faveList}
        setFaveList={setFaveList}
        removeFavorite={removeFavorite}
        setCurrentImageDetails={setCurrentImageDetails}
        addImageToFavorites={addImageToFavorites}
      />}

      {neuroglancerLink ? <ImageViewer neuroglancerLink={neuroglancerLink}/> : <div className='middle noLink'>Choose an image to view from the menu to the right &nbsp;  
      <FontAwesomeIcon icon={icon({name: 'square-up-right'})} /> </div>}
      
      <Footer />
    </div>
  );
}

export default App;
