import React from 'react'
import logo from '../brainicon.png';

function Header() {
  return (
    <div className="nav">
      <a href="https://imageexplorer.brainimagelibrary.org"><img src={logo} className="bil-logo" alt="logo"></img></a>
      <div className="bng_title">
        <h1 className="headline">BIL Image Explorer</h1>
        <div className="sublinks">
          <a className="link" href="http://www.brainimagelibrary.org/">Brain Image Library</a> | 
          <a className="link" href="https://submit.brainimagelibrary.org/"> Submit Portal</a> | 
          <a className="link" href="https://brainapi.brainimagelibrary.org/"> BrAinPi</a>
        </div>
      </div>
    </div>
  )
}

export default Header