import React from 'react'
import logo from '../brainicon.png';

function Footer() {
  return (
    <div className="bottom">
        Brain Image Library <a href="https://imageexplorer.brainimagelibrary.org"><img src={logo} className="bil-logo-footer" alt="logo"></img></a> Image Explorer 2023
    </div>
  )
}

export default Footer